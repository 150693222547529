<template>
  <div class="user">
    <!-- 面包屑导航 -->
    <el-card>
      <!-- 头 -->
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input placeholder="请输入内容" v-model="getInfo.info">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
        <el-col :span="8"> </el-col>
        <el-tooltip
          class="item"
          effect="dark"
          content="上传广告图"
          placement="top-start"
        >
          <el-button  type="primary"  @click="addBannerCart">添加轮播图分类</el-button>
        </el-tooltip>
      </el-row>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        v-if="tableData"
      >
       <!-- <el-table-column type="expand">
          <template slot-scope="props">
            <el-tag
              style="margin-right: 10px; margin-top: 5px"
              v-for="(item, index) in props.row"
              :key="index"
              >{{ item }}</el-tag
            >
          </template>
        </el-table-column> -->

         <el-table-column
          align="center"
          prop="name"
          label="小程序页面名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="path"
          label="小程序页面路径"
        ></el-table-column>
        <!-- <el-table-column
          align="center"
          prop="title"
          label="轮播图标题"
        ></el-table-column> -->
        <el-table-column
          align="center"
          prop="status"
          label="状态"
        >
        <template slot-scope="scope">
             <el-switch
  v-model="scope.row.status"
  active-color="#13ce66"
  inactive-color="#ff4949">
</el-switch>
        </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
                          <el-tooltip
              class="item"
              effect="dark"
              content="查看轮播图分类管理详情"
              placement="top-start"
            >
              <el-button
                icon="el-icon-search"
                @click="lookBanner(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="修改轮播图分类管理"
              placement="top-start"
            >
              <el-button
                icon="el-icon-edit"
                type="primary"
                @click="updataPicture(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除轮播图分类管理"
              placement="top-start"
            >
              <el-button
                icon="el-icon-delete"
                type="danger"
                @click="delPicture(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @current-change="pageChange"
        @size-change="sizeChange"
        :page-sizes="[7, 15, 20]"
        layout="total, sizes, prev, pager, next"
        :page-size="this.getInfo.page_num"
        background
        :total="exp.num"
      >
      </el-pagination>
    </el-card>
    <!-- 增加轮播图分类模态框 -->
    <el-dialog
      :visible.sync="AddDialogFormVisible"
      :append-to-body="true"
      :before-close="AddhandleClose"
    >
      <div slot="title" class="dialog-footer">
        <div class="title">增加轮播图分类</div>
      </div>
      <div class="diaBox">
        <el-form
          :model="bannerForm"
          label-width="200px"
          ref="ruleForm"
          :rules="rules"
        >
          <!-- <el-form-item label="轮播图标题" prop="ids">
            <el-select
              filterable
              v-model="bannerForm.banner_id"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in bannerids"
                :key="item.value"
                :label="item.title"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
            <el-form-item label="页面名称" prop="pathName">
            <el-input
              v-model="bannerForm.path_name"
              placeholder=""
              style="width: 220px"
            ></el-input>
          </el-form-item>
          <el-form-item label="小程序页面路径" prop="path">
            <el-input
              v-model="bannerForm.path"
              placeholder=""
              style="width: 220px"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelAdd">取 消</el-button>
        <el-button type="primary" 
          @click="addBannerDig('ruleForm')">确 认</el-button
        >
      </div>
    </el-dialog>
    <!-- 轮播图分类编辑模态框 -->
      <el-dialog
      :visible.sync="updataDialogFormVisible"
      :append-to-body="true"
      :before-close="AddhandleClose"
    >
      <div slot="title" class="dialog-footer">
        <div class="title">修改轮播图分类</div>
      </div>
      <div class="diaBox">
        <el-form
          :model="bannerForm"
          label-width="220px"
          ref="ruleForm"
          :rules="rules"
        >
          <!-- <el-form-item label="轮播图标题" prop="ids">
            <el-select
              filterable
              v-model="bannerForm.banner_id"
              clearable
              placeholder="请选择"
              label-width="220px"
            >
              <el-option
                v-for="item in bannerids"
                :key="item.value"
                :label="item.title"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
             <el-form-item label="小程序页面名称" prop="pathName">
            <el-input
              v-model="bannerForm.path_name"
              placeholder=""
              style="width: 220px"
            ></el-input>
          </el-form-item>
          <el-form-item label="小程序页面路径" prop="path">
            <el-input
              v-model="bannerForm.path"
              placeholder=""
              style="width: 220px"
            ></el-input>
          </el-form-item>
           <el-form-item label="状态" prop="status">
             <el-switch
                v-model="bannerForm.status"
                active-color="#13ce66"
                inactive-color="#ff4949">
                </el-switch>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelUpdata">取 消</el-button>
        <el-button type="primary" 
          @click="UpdataBannerDig('ruleForm')">确 认</el-button
        >
      </div>
    </el-dialog>
    <!-- 查看轮播图详情模态框 -->
      <el-dialog
      :visible.sync="detailDialogFormVisible"
      :append-to-body="true"
      :before-close="AddhandleClose"
    >
      <div slot="title" class="dialog-footer">
        <div class="title">轮播图分类详情</div>
      </div>
      <div class="diaBox">
        <el-form
          :model="bannerForm"
          label-width="220px"
        >
            <el-form-item label="商品ID">
            <el-input
              v-model="bannerForm.id"
              placeholder=""
              style="width: 220px"
            ></el-input>
          </el-form-item>
          <el-form-item label="轮播图分类ID">
            <el-select
              filterable
              v-model="bannerForm.banner_id"
              clearable
              placeholder="请选择"
              label-width="220px"
            >
              <el-option
                v-for="item in bannerids"
                :key="item.value"
                :label="item.title"
                :value="item.id"
               label-width="220px"
              >
              </el-option>
            </el-select>
          </el-form-item>
             <el-form-item label="小程序页面名称" prop="pathName">
            <el-input
              v-model="bannerForm.path_name"
              placeholder=""
              style="width: 220px"
            ></el-input>
          </el-form-item>
          <el-form-item label="小程序页面路径" prop="path">
            <el-input
              v-model="bannerForm.path"
              placeholder=""
              style="width: 220px"
            ></el-input>
          </el-form-item>
           <el-form-item label="状态" prop="status">
             <el-switch
                v-model="bannerForm.status"
                active-color="#13ce66"
                inactive-color="#ff4949">
                </el-switch>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="cancelUpdata">取 消</el-button> -->
        <el-button type="primary" 
          @click="detailBannerDig">确 认</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  PostPrictureListID,
} from "@/api/pricture/prictureList.js";
import {
  getBannerCatList,
  getBannerCatAdd,
  getBannerCatDel,
  getBannerCatEdit
} from "@/api/bannerCart.js";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
      var validateIDs = (rule, value, callback) => {
      if (this.bannerForm.banner_id === "") {
        callback(new Error("轮播图标题不能为空！"));
      } else {
        callback();
      }
    };
    var validatePath = (rule, value, callback) => {
      if (this.bannerForm.path === "") {
        callback(new Error("小程序路径不能为空！"));
      } else {
        callback();
      }
    };
    var validatePathName = (rule, value, callback) => {
      if (this.bannerForm.path_name === "") {
        callback(new Error("小程序名称不能为空！"));
      } else {
        callback();
      }
    };
    //这里存放数据
    return {
        rules: {
        path: [{ validator: validatePath, trigger: "blur" }],
        pathName: [{ validator: validatePathName, trigger: "blur" }],
        ids: [{ validator: validateIDs, trigger: "blur" }],
      },
        //增加轮播图分类表单
        bannerForm:{
            id:'',
             banner_id:'',
             path_name:'',
             status:true,
             path:''
        },
        AddDialogFormVisible: false, //增加轮播图分类模态框flag
        updataDialogFormVisible:false,//修改轮播图分类模态框flag
        detailDialogFormVisible:false,//详情轮播图分类模态框flag
      //总条数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: "",
        page_code: 1,
        page_num: 15,
      },
      //表格数据
      tableData: [],
      //轮播图选择框
      bannerids: [],
    };
  },

  //监听属性 类似于data概念
  computed: {
  },
  //监控data中的数据变化
  watch: {
       AddDialogFormVisible(newVal, oldVal) {
      if (!newVal) {
        this.bannerForm = this.$options.data().bannerForm;
      }
    },
    updataDialogFormVisible(newVal, oldVal) {
      if (!newVal) {
        this.bannerForm = this.$options.data().bannerForm;
      }
    },
    detailDialogFormVisible(newVal, oldVal) {
      if (!newVal) {
        this.bannerForm = this.$options.data().bannerForm;
      }
    },
  },
  //方法集合
  methods: {
      //查看详情按钮
      lookBanner(item){
          this.detailDialogFormVisible=true;
           this.bannerForm={
            id:item.id,
            banner_id:item.banner_id?parseInt(  item.banner_id):item.banner_id,
            path_name: item.name,
             status:item.status,
            path:item.path
        }
      },
      //详情模态框增加按钮
      detailBannerDig(){
      this.detailDialogFormVisible=false;
      },
      //修改模态框取消按钮
      cancelUpdata(){
          this.updataDialogFormVisible=false;
      },
      //修改模态框增加按钮
      cancelAdd(){
            this.AddDialogFormVisible=false;
      },
       //修改轮播图分类
      updataPicture(item){
        console.log(item)
        this.bannerForm={
            banner_id: item.banner_id,
            path_name: item.name,
            status:item.status,
            path:item.path,
            banner_cat_id:item.id
        }
        this.updataDialogFormVisible=true
      },
    //删除轮播图分类
    delPicture(id){
        console.log(id)
        var that=this;
          this.$confirm("确认删除？")
            .then((_) => {
              that.AddDialogFormVisible = false;
              var data={
                    banner_cat_id:id
              }
              that.getBannerCatDel(data);
            })
            .catch((err) => {
              console.log(err);
            });
    },
    //增加轮播图分类
    addBannerCart(){
         this.AddDialogFormVisible=true;
    },
    //修改轮播图分类模态框确认按钮
    UpdataBannerDig(formName){
              var that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
             this.$confirm("确认修改？")
            .then((_) => {
              that.updataDialogFormVisible = false;
              that.getBannerCatEdit(this.bannerForm)
            })
            .catch((err) => {
              console.log(err);
            });
        }else {
          console.log("error submit!!");
          return false;
        }
        })
        
    },
    //增加轮播图分类模态框确认按钮
    addBannerDig(formName ){
           var that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
             this.$confirm("确认增加？")
            .then((_) => {
              that.AddDialogFormVisible = false;
              that.getBannerCatAdd(that.bannerForm);
              that.tableData=[]
              var data = {
                page: this.getInfo.page_code,
                limit: this.getInfo.page_num,
                };
                //获取轮播图分类列表
                that.getBannerCatList(data);
            })
            .catch((err) => {
              console.log(err);
            });
        }else {
          console.log("error submit!!");
          return false;
        }
        })
        
    },
    //修改每页数量
    sizeChange(value) {
      this.getInfo.page_num = value;
      var data = {
        page: this.getInfo.page_code,
        list_num: this.getInfo.page_num,
      };
      console.log(data);
      this.tableData = [];
      this.getBannerCatList(data);
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value;
      var data = {
        page: this.getInfo.page_code,
        list_num: this.getInfo.page_num,
      };
      console.log(data);
      this.tableData = [];
      this.getBannerCatList(data);
    },
      //点击模态层 隐藏上传模态框
    AddhandleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    /**-----------------网络请求生命周期函数--------------------- */
    //增加轮播图分类
    getBannerCatAdd(data){
      let res=getBannerCatAdd(data);
         res.then((res)=>{
                   if(res.data.code==1){
              this.tableData=[]
              var data = {
                page: this.getInfo.page_code,
                limit: this.getInfo.page_num,
                };
                //获取轮播图分类列表
                this.getBannerCatList(data);
             this.$message({
              message: res.data.message,
              type: "success",
            });
         }else{
              this.$message.error(res.data.message);
         }
         }).catch((err)=>{
             console.log(err)
         })
    },
    //修改轮播图列表
     getBannerCatEdit(data){
         let res= getBannerCatEdit(data);
         res.then((res)=>{
                   if(res.data.code==1){
              this.tableData=[]
              var data = {
                page: this.getInfo.page_code,
                limit: this.getInfo.page_num,
                };
                //获取轮播图分类列表
                this.getBannerCatList(data);
             this.$message({
              message: res.data.message,
              type: "success",
            });
         }else{
              this.$message.error(res.data.message);
         }
         }).catch((err)=>{
             console.log(err)
         })
    },
    //删除轮播图列表
    getBannerCatDel(data){
         let res= getBannerCatDel(data);
         res.then((res)=>{
                   if(res.data.code==1){
              this.tableData=[]
              var data = {
                page: this.getInfo.page_code,
                limit: this.getInfo.page_num,
                };
                //获取轮播图分类列表
                this.getBannerCatList(data);
             this.$message({
              message: res.data.message,
              type: "success",
            });
         }else{
              this.$message.error(res.data.message);
         }
         }).catch((err)=>{
             console.log(err)
         })
      
    },
      //获取轮播图分类列表
    async getBannerCatList(data) {
      let res = await getBannerCatList(data);
      console.log(res);
      if (res.data.code == 1) {
                    res.data.data.data.forEach((item,i)=>{
              if(item.status==1){
                  res.data.data.data[i].status=true
              }else{
                  res.data.data.data[i].status=false
              }
          })
        this.exp.num = res.data.data.total;
        this.tableData = this.tableData.concat(res.data.data.data);
        console.log(this.tableData)
      } else {
        this.$message.error(res.data.message);
      }
    },
    //轮播图列表
    async PostPrictureListID() {
      let res = await PostPrictureListID();
    //   console.log(res)
      if (res.data.code == 1) {
        this.bannerids = res.data.data;
      } else {
        this.$message.error(res.data.message);
      }
      console.log(res, "轮播图列表");
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    var data = {
      page: this.getInfo.page_code,
      limit: this.getInfo.page_num,
    };
      //获取轮播图分类列表
     this.getBannerCatList(data);
     //轮播图列表
     this.PostPrictureListID();
  },

  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
};
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
.title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.diaBox {
  display: flex;
  justify-content: center;
}
.imgUrl {
  margin-left: 50%;
  transform: translateX(-50%);
  width: 350px;
  height: 200px;
  overflow: hidden;
  text-align: center;
}
</style>